<template>
  <div class="page">
    <div class="card">
      <van-row :gutter="8" style="line-height: 1.8em">
        <van-col :span="14">
          <!-- <div class="item"><b>物品编码:</b>物品编码</div> -->
          <div class="item"><b>名称:</b>{{ model.THING_NAME }}</div>
          <div class="item"><b>位置:</b>{{ model.LOCATION }}</div>
          <div class="item"><b>数量:</b>{{ model.COUNT }}</div>
          <div class="item"><b>金额:</b>{{ model.AMOUNT }}</div>
          <div class="item"><b>图片:</b>
            <van-image v-if="model.IMAGEKVS.length > 0" :src="model.IMAGEKVS[0].Value"
              @click="prvImage(model.IMAGEKVS[0].Value, null)" class="img" />
            <template v-else>无</template>
          </div>
        </van-col>
      </van-row>
      <van-divider />
      <div><b>状态:</b>{{ model.STATUS_TEXT }}</div>
      <div style="margin-top: 5px;"><b>报损原因:</b>{{ model.CAUSE }}</div>
      <div style="margin-top: 5px;"><b>备注:</b>{{ model.NOTE }}</div>
      <van-divider />
      <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between">
        <div><b>申报人:</b>{{ model.CREATED_NAME }}</div>
        <div><b>申报日期:</b>{{ model.CREATED_TIME }}</div>
      </div>
      <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between"
        v-if="model.STATUS == 0">
        <div><b>审批人:</b>
          <span style="padding: 0 5px;" v-for="item1 in model.ApproveTeacherList" :key="item1">{{ item1 }}</span>
        </div>
      </div>
      <!--已审批样式-->
      <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between"
        v-if="model.APPROVE_NAME">
        <div><b>审批人:</b>{{ model.APPROVE_NAME }}</div>
        <div><b>审批日期:</b>{{ model.APPROVE_TIME }}</div>
      </div>
      <!--待审批-->
    </div>
    <div class="btns" v-if="model.CanApprove == 1">
      <van-button plain type="primary" size="normal" round @click="pass()" style="width: 25%">通过
      </van-button>
      <van-button plain type="danger" size="normal" round @click="reject()" style="width: 25%">拒绝
      </van-button>
      <van-button plain type="info" size="normal" round @click="revoke(model.ID)" style="width: 25%">撤消
      </van-button>
    </div>
    <van-popup v-model="reson.show" position="bottom" round>
      <div style="padding: 20px;text-align: center">拒绝原因</div>
      <van-field border v-model="reson.val" type="textarea" rows="3" autosize show-word-limit maxlength="100"
        placeholder="请输入拒绝原因" />
      <div style="padding:10px 50px">
        <van-button type="primary" size="large" round text="确认提交" loading-text="正在提交" @click="save" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview, Dialog } from 'vant';

export default {
  components: { [ImagePreview.Component.name]: ImagePreview.Component },
  data() {
    return {
      id: this.$route.query.id,
      model: {},
      reson: {
        show: false,
        val: ''
      },
      data: {

      }
    }
  },
  created() {
    this.getModel()
  },
  methods: {
    getModel() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/LOSS/RecordApi/GetModel",
        data: { id: this.id },
        completed(res) {
          self.model = res.DATA
          // console.log(res)
        }
      })
    },
    pass() {
      Dialog.confirm({
        title: "确认通过审核?",
      }).then(() => {
        // console.log('确认')
        this.data = {
          ID: this.model.ID,
          STATUS: 1
        }
        this.approve();
      })
    },
    reject() {
      this.reson.show = true
      this.data = {
        ID: this.model.ID,
        STATUS: 2
      }
    },
    save() {
      this.data.NOTE = this.reson.val
      this.approve();
    },
    approve() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/LOSS/RecordApi/Approve",
        data: this.data,
        completed(res) {
          if (res.DATA) {
            self.$router.go(-1)
          }
        }
      })
    },

    prvImage(url1, url2) {
      let us = [];
      if (url1 != undefined) us.push(url1)
      if (url2 != undefined) us.push(url2)
      ImagePreview(us)
    },

    revoke(id) {
      let self = this;
      Dialog.confirm({
        title: "撤消报损",
        message: "真的要撤消这次报损吗？",
      }).then(() => {
        this.whale.remote.getResult({
          url: "/api/Mobile/LOSS/RecordApi/Revoke",
          data: { ID: id },
          completed(res) {
            if (res.DATA)
              self.$router.go(-1)
          }
        })
      })
    },
  }
}
</script>
<style scoped>
.page {
  padding-bottom: 88px;
}

.card {
  margin: 10px;
  border-radius: 15px;
  background: #fff;
  padding: 20px;
  font-size: 14px;
}

.card div b {
  color: #999;
  font-weight: normal;
  margin-right: 5px
}

.item {
  display: flex;
  align-items: flex-start;
}

.item b {
  flex: 0 0 auto;
}

.item .img {
  min-width: 100px;
  min-height: 100px;
  max-width: 50%;
}

.btns {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around
}
</style>